import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Output() onClose = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleClose() {
    this.onClose.emit();
  }
}
