// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiContratacao: {
    host: '//cndcontratacaoapi.hmg.marlin.com.br/api',
    // host: '//localhost:56172/api',
    acesso: {
      login: 'condominiodigital@apsa',
      senha: '83892d5910ea'
    },
    endpoints: {
      token: '/Login',
      planos: {
        getAll: '/Plano/getAll',
        getCalculo: '/Plano/getCalculo',
        getCpfParceiro: '/Contratacao/getCpfParceiro',
        cadastroLead: '/Lead'
      },
      home: {
        cadastraContato: '/Lead/cadastraContato',
        getPlanos: '/Plano/getAll'
      },
      simulacao: {
        getValor: '/Plano/getCalculo'
      },
      contratacao: {
        getCNPJ: '/Contratacao/getCnpjParceiro',
        getCEP: '/Contratacao/getCepParceiro',
        criaContrato: '/Contratacao/criarContrato',
        checaAssinatura: '/Contratacao/assinadoPeloCliente'
      }

  }
  }
};
