<div class="PopupHeader">
  <div class="PopupPlanImage">
    <img [src]="planImage" alt="Imagem do plano">
  </div>

  <h1 class="PopupTitle">{{ plan.titulo }}</h1>
</div>

<div class="PopupBody">
  <p class="PopupParagraph" [innerHTML]="plan.descricao">
  </p>

  <ul class="PopupFeaturesList">
    <li
      class="PopupFeatureItem"
      *ngFor="let feature of plan.servicos"
      [innerHTML]="feature.descricao"
    >
    </li>
  </ul>
</div>

<div class="PopupFooterAction" *ngIf="!isSinglePlan">
  <div class="PopupFooterAction_Item">
    <button
      class="SimulateButton"
      (click)="emitSubmit()"
    >
      Simular
    </button>
  </div>
</div>