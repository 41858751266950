import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnDestroy
} from "@angular/core";


import { LightBoxesService } from "../lightboxes.services";

@Component({
  selector: 'app-mural-cadastrado',
  templateUrl: './mural-cadastrado.component.html',
  styleUrls: ['./mural-cadastrado.component.scss'],
})
export class MuralCadastradoComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;
  private element: any;
  private promisse: Promise<boolean>;
  private resolve;

  constructor(private service: LightBoxesService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    // TODO: Retirado para não bugar
    // this.service.remove(this.id);
    this.element.remove();
  }

  open(mensagem: { title: string, text: string }, time: number = 1000): Promise<boolean> {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('Popup_Header_Title')[0].innerHTML = mensagem.title;
    this.element.getElementsByClassName('Popup_Content_MsgDefault')[0].innerHTML = mensagem.text;
    //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.add(this.className);

    if (time === 0) return new Promise(resolve => { this.resolve = resolve; });

    return new Promise(resolve => {
      setTimeout(function () {
        a.firstChild.classList.remove(n);
        //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.remove(this.className);
        resolve(true);
      }, time);
    });
  }

  confirmar(): void {
    this.resolve(true);
    this.element.firstChild.classList.remove(this.className);
  }

  close(): void {
    this.resolve(false);
    this.element.firstChild.classList.remove(this.className);
  }
}
