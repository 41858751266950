import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { GetPlanosDTO } from 'src/app/modules/home/services/DTO/GetPlanos.DTO';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plan-compartive-mobile-popup',
  templateUrl: './plan-compartive-mobile-popup.component.html',
  styleUrls: ['./plan-compartive-mobile-popup.component.scss']
})
export class PlanCompartiveMobilePopupComponent implements OnChanges {

  public slickConfig = {
    mobileFirst: true,
    dots: true,
    infinite: true,
    arrows: true,
    prevArrow: '<div class="slick-arrow slick-prev"><i class="fas fa-caret-left"></i></div>',
    nextArrow: '<div class="slick-arrow slick-next"><i class="fas fa-caret-right"></i></div>',
    adaptiveHeight: true,
    slidesToShow: 1,
  };

  @Input() isOpen: boolean = false;
  @Output() onClose = new EventEmitter();
  @Input() slideToFocus: number;
  @ViewChild('carousel', { static: false }) carousel: any;
  @Input() planoEssencial: GetPlanosDTO;
  @Input() planoCompleto: GetPlanosDTO;

  constructor(
    public router: Router
  ) { }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.makeSlickfocusSpecificSlide(this.slideToFocus);
    window.setTimeout(() => this.triggerSlickRefresh(), 100);
  }

  makeSlickfocusSpecificSlide(slideIndex: number) {
    const carouselElement = this.carousel?.el?.nativeElement;

    if(carouselElement)
      carouselElement.slick.goTo(slideIndex);
  }

  triggerSlickRefresh() {
    const carouselElement = this.carousel?.el?.nativeElement;

    if(carouselElement?.slick)
      carouselElement.slick.refresh();
  }

  contratarEssencial() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', 'assets/img/plano 1.png');
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(this.planoEssencial));

    this.router.navigate(['plano']);
  }

  contratarCompleto() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', 'assets/img/plano 2.png');
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(this.planoCompleto));

    this.router.navigate(['plano']);
  }

  emitClose() {
    this.onClose.emit();
  }
}
