import { Injectable } from '@angular/core';
import { GetPlanosDTO } from 'src/app/modules/home/services/DTO/GetPlanos.DTO';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { StateService } from './stateService';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  public url = environment.apiContratacao.host
  public methods = environment.apiContratacao.endpoints.home

  constructor(
    public stateService: StateService,
    public http: HttpClient,
  ) { }

  private getHeader(){
    return new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${this.stateService.getTokenApiCondominioDigital()}`
    })
  }

  public getPlansFromAPI(): Observable<GetPlanosDTO[]> {
    const url = this.url + this.methods.getPlanos;
    const options = {
      headers: this.getHeader(),
    };

    return this.http.get<GetPlanosDTO[]>(url, options);
  }
}
