import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardChildGuard } from './shared/interceptors/auth-guard-child.guard';
import { HomeComponent } from './modules/home/home.component';
import { IndexComponent } from './modules/plano/pages/index/index.component';

import { HeaderAndFooterLayoutComponent } from './containers/header-and-footer-layout/header-and-footer-layout.component';

const routes: Routes = [
  { path: '', component: IndexComponent, canActivate: [AuthGuardChildGuard] },
  { path: '',   redirectTo: '/', pathMatch: 'full' }, // redirect to index
  {
    path: 'simulacao',
    component: HeaderAndFooterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/simulacao/simulacao.module').then(m => m.SimulacaoModule)
      },
    ],
  },
  {
    path: 'plano',
    component: HeaderAndFooterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/plano/plano.module').then(m => m.PlanoModule)
      },
    ],
  },
  {
    path: 'contratacao',
    component: HeaderAndFooterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/contratacao/contratacao.module').then(m => m.ContratacaoModule)
      },
    ],
  },
  {
    path: 'faq',
    component: HeaderAndFooterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/faq/faq.module').then(m => m.FaqModule)
      },
    ],
  },
  
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuardChildGuard]  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
