import { ElementRef } from '@angular/core';
export interface ISlickRef {
  el: ElementRef;
};

export function updateSlick(slickRef: ISlickRef) {
  const carouselElement = slickRef?.el?.nativeElement;

  if(carouselElement?.slick) {
    carouselElement.slick.refresh();
  }
  else {
    throw Error(`shared/utils/slick.ts: Could not refresh slick, reference is "${carouselElement}"`);
  }
}