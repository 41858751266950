import { Injectable, Input } from '@angular/core';
import { Clicksign } from '../utils/ClickSign';
import { Router } from '@angular/router';
import { ContratacaoService } from 'src/app/modules/contratacao/services/contratacao.service';

@Injectable({
  providedIn: 'root'
})
export class ClickSignService {
  public clickSignWidget: any;
  private request_signature_key: string = 'b28e8a93-3707-472c-971f-64a29def8d70';
  // @Input() request_signature_key: string ;
  private endpointHost: string = 'https://sandbox.clicksign.com/';
  private originHost: string = 'http://cndcontratacao.hmg.marlin.com.br/';
  // @Input() originHost;

  constructor(private router: Router, private contratacaoService: ContratacaoService) {}

  run(containerElementID: string, key?: string) {
    if (this.clickSignWidget) {
      this.clickSignWidget.unmount();
    }

    if(key) this.request_signature_key = key;

    this.clickSignWidget = Clicksign(this.request_signature_key);
    this.clickSignWidget.endpoint = this.endpointHost;
    this.clickSignWidget.origin = this.originHost;
    this.clickSignWidget.mount(containerElementID);

    this.clickSignWidget.on('loaded', e => {
      console.log('loaded!')
    });
    this.clickSignWidget.on('signed', e => {
      console.log('signed!')
      setTimeout( () => {
        this.router.navigate(['/'], { queryParams: { contratacao: true} })
        sessionStorage.clear()
      })
      
    });


  }

}
