<div
  class="PlanPopupComponent"
  [ngClass]="{ '__is-active': isOpen }"
>
  <div class="Popup">

    <div class="PopupClose" (click)="emitClose()">
      <i class="fas fa-times"></i>
    </div>

    <ng-container *ngIf="!showOnlyOnePlan; else onePlan">
      <ngx-slick-carousel
        [config]="slickConfig"
        class="PlansCarousel"
        #carousel
      >
        <div class="slide" *ngFor="let plan of plans; index as i" ngxSlickItem>
          <app-plan
            [plan]="plan"
            planImage="assets/img/plano {{i+1}}.png"
            (onSubmit)="handleSimulate($event)"
          >
          </app-plan>
        </div>
      </ngx-slick-carousel>
    </ng-container>

    <ng-template #onePlan>
      <app-plan
        [plan]="showOnlyOnePlan"
        [planImage]="imgName"
        (onSubmit)="emitPlanChange($event)"
        [isSinglePlan]="true"
      >
      </app-plan>
    </ng-template>
  </div>
</div>