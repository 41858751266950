import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { GetPlanosDTO } from 'src/app/modules/home/services/DTO/GetPlanos.DTO';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit {
  
  @Input() plan: GetPlanosDTO;
  @Input() planImage: string;
  @Input() isSinglePlan: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @ViewChild('simulateButton', { static: false }) simulateButton: ElementRef;

  ngOnInit() {
  }
  
  emitClose() {
    this.onClose.emit();
  }

  emitSubmit() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', this.planImage);

    this.onSubmit.emit(this.plan);
  }
}
