import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StateService } from 'src/app/shared/services/stateService';
import { environment } from 'src/environments/environment';
import { CpfParceiroDTO } from './DTO/CpfParceiro.DTO';

@Injectable({
  providedIn: 'root'
})
export class PlanoService {
  
  private url: string;
  private methods;

  constructor(
    private http: HttpClient,
    private stateService: StateService
  ) {
    this.url = environment.apiContratacao.host
    this.methods = environment.apiContratacao.endpoints.planos
   }

   private getHeader(){
     return new HttpHeaders({
       'Content-Type' : 'application/json',
       'Authorization' : `Bearer ${this.stateService.getTokenApiCondominioDigital()}`
     })
   }

   getCpfParceiro(cpf){
    const header = this.getHeader()
    const parametros = new HttpParams()
      .set('cpf', cpf)

    return this.http.get<CpfParceiroDTO>(`${this.url + this.methods.getCpfParceiro}`,
      {headers:header, params:parametros, observe:'response'})
   }

   cadastroLead(form, cepValido, acao?){
     
     let telefone, celular;
     let primeiroDigito = form.telefone.substr(2,1);
     if(primeiroDigito != '9') telefone = form.telefone;
     if(primeiroDigito == '9') celular = form.telefone

     const planoSelecionado = JSON.parse(sessionStorage.getItem('planoSelecionado'))
     const planosDisponiveis = JSON.parse(sessionStorage.getItem('planosDisponiveis'))
     const unidadesFuncionarios = JSON.parse(sessionStorage.getItem('unidadesFuncionarios'))
    //  console.log(unidadesFuncionarios)
     const body = {
      nome: form.nome,
      cpfcnpj: form.cpf,
      telefone: telefone,
      celular: celular,
      email: form.email,
      nomecondominio: form.nomeDoCondominio,
      uidPlano: (planoSelecionado)? planoSelecionado.uid : planosDisponiveis[0].uid ,
      cepCliente: form.cepDoCondominio,
      areaAtendida: cepValido,
      quantidadeUnidade: unidadesFuncionarios? unidadesFuncionarios.unidades: 0,
      quantidadeFuncionario: unidadesFuncionarios? unidadesFuncionarios.funcionarios: 0,
      acaoSelecionada: acao? acao:null,
      sexo: form.genero,
      nomeMae: form.nomeMae
     }

    return this.http.post(`${this.url + this.methods.cadastroLead}`, body,
      {headers: this.getHeader()})
   }
}
