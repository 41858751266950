<div
  class="CompartivePlanPopupMobileComponent"
  [ngClass]="{ '__is-active': isOpen }"
>
  <div class="Popup">
    <div class="PopupClose">
      <i class="fas fa-times" (click)="emitClose()"></i>
    </div>

    <h1 class="Title">
      Planos
    </h1>

    <p class="Description">
      O Condomínio Digital Apsa é a forma de administração mais eficiente
      para o seu condomínio. Seus planos combinam o que há de melhor em
      controle administrativo, financeiro e gerencial, além da possibilidade
      de gestão de funcionários e folha de pagamento. 
    </p>

    <p class="Description">
      Conheça a tradição da consultoria condominial Apsa aliada 
      à experiência completamente digital, inovadora, inclusiva 
      e participativa para você e seus condôminos.
    </p>

    <ngx-slick-carousel
      [config]="slickConfig"
      class="PlansComparativeCarousel"
      #carousel
    >
      <div class="PlanContainer" ngxSlickItem>
        <div class="PlanHeader">
          <h2 class="PlanName">
            PLANO DIGITAL BÁSICO
          </h2>

          <div class="PlanImage">
            <img src="../../../../assets/img/plano 2 white.png" />
          </div>
        </div>

        <div class="PlanServiceLabel">
          Serviços
        </div>

        <div class="PlanServiceContainer">
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">Atendimento às demandas administrativas</p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Cotas condominiais e boletos digitais
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Realização de pagamentos
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Controle orçamentário
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Extratos, relatórios e prestações de 
                contas interativos
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Digitalização de documentos 
                condominiais digitalizados 
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>
          
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Agendamento e reservas de espaço 
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Mural de avisos
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Livro de ocorrências
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Cotação online de serviços
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Admissão de funcionários
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Controle de ponto 
                e horas extras
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Controle e simulação 
                de férias
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
            </div>
          </div>
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Processamento de folha 
                de pagamento
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Simulação de rescisões
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
            </div>
          </div>
        </div>

        <button class="PlanButton" (click)="contratarEssencial()">
          Contrate Agora
        </button>
      </div>

      <div class="PlanContainer" ngxSlickItem>
        <div class="PlanHeader">
          <h2 class="PlanName">
            PLANO DIGITAL COMPLETO
          </h2>

          <div class="PlanImage">
            <img src="../../../../assets/img/plano 1 white.png" />
          </div>
        </div>

        <div class="PlanServiceLabel">
          Serviços
        </div>

        <div class="PlanServiceContainer">
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">Atendimento às demandas administrativas</p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Cotas condominiais e boletos digitais
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Realização de pagamentos
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Controle orçamentário
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Extratos, relatórios e prestações de 
                contas interativos
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Digitalização de documentos 
                condominiais digitalizados 
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>
          
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Agendamento e reservas de espaço 
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Mural de avisos
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>

          </div>
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Livro de ocorrências
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Cotação online de serviços
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Admissão de funcionários
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Controle de ponto 
                e horas extras
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Controle e simulação 
                de férias
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>
          
          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Processamento de folha 
                de pagamento
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="PlanServiceRow">
            <div class="PlanServiceDescriptionColumn">
              <p class="PlanServiceItemDescription">
                Simulação de rescisões
              </p>
            </div>

            <div class="PlanServiceCheckColumn">
              <i class="fas fa-check"></i>
            </div>
          </div>
        </div>

        <button class="PlanButton" (click)="contratarCompleto()">
          Contrate Agora
        </button>
      </div>
    </ngx-slick-carousel>
  </div>
</div>