<div class="LandingAPSA">
  <div class="LandingAPSA_Home" id="Home">
    <div class="LandingAPSA_Home_Container">
      <header class="LandingAPSA_Header">
        <div class="LandingAPSA_Header_Container">
          <div class="Header_Container_Logotipo">
            <a href="#Home" class="Scroll">
              <img src="assets/img/logotipo-apsa.png">
            </a>
          </div>
<!-- 
          <div class="Header_Container_Menu">
            <ul>
              <li>
                <a (click)="scrollSuave('Sobre')" class="Scroll">Sobre</a>
              </li>

              <li>
                <a (click)="scrollSuave('Planos')" class="Scroll">Planos</a>
              </li> -->

              <!-- <li>
                <a (click)="scrollSuave('Planos')" class="Scroll">Simule</a>
              </li>

              <li>
                <a (click)="scrollSuave('Servicos')" class="Scroll">Serviços</a>
              </li> -->

              <!-- <li>
                <a (click)="scrollSuave('Depoimentos')" class="Scroll">Depoimentos</a>
              </li>

              <li>
                <a routerLink="/faq">Ajuda</a>
              </li>

              <li>
                <a (click)="scrollSuave('Contato')" class="Scroll">Contato</a>
              </li>
            </ul>
          </div> -->

          <!-- <div class="Header_Container_MenuIconeMobile" (click)="openMenuMobile()">
            <i class="fas fa-bars"></i>
          </div> -->
        </div>
      </header>

      <div class="Home_Container_Content">
        <div class="Container_Content_Titulo">
          Gestão Condominial Apsa: muito mais eficiência,<br>
          tecnologia e comodidade na administração do seu condomínio.
        </div>

        <div class="Container_Content_Subtitulo">
          Prestação de contas digital, envio de documentos para pagamento
          e correspondências, agendamento para utilização de espaços comuns,
          registro de ocorrências, comunicados diversos, enquetes para
          assembleias virtuais e muito mais para a gestão do seu
          condomínio. <b class="Highlight">Isso é viver bem!</b>
        </div>

        <div class="Container_Content_Button __is-orange">
          <i class="fas fa-caret-left"></i>

          <div class="Component_ButtonCreative" (click)="scrollSuave('Planos')">
            Conheça nossos planos
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="LandingAPSA_Sobre" id="Sobre">
    <div class="LandingAPSA_Sobre_Container">
      <div class="Sobre_Container_Content">
        <div class="Container_Content_Titulo">
          Inovando sempre para o seu bem-estar
        </div>

        <div class="Container_Content_Subtitulo">
          Com 89 anos de história, somos líder no mercado de gestão condominial.
          Em constante processo de inovação, estamos sempre a frente porque usamos a tecnologia
          para desenvolver soluções e serviços personalizados para o seu condomínio.
        </div>
      </div>

      <div class="DesktopAboutCards">
        <div class="Sobre_Container_Cards">
          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card7.png">
            </div>

            <div class="Cards_Item_Titulo">
              Comodidade
            </div>

            <div class="Cards_Item_Texto">
              Tudo o que você precisa para o seu condomínio em um só lugar
            </div>
          </div>

          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card4.png">
            </div>

            <div class="Cards_Item_Titulo">
              Agilidade
            </div>

            <div class="Cards_Item_Texto">
              Processos totalmente digitalizados com menos burocracia
            </div>
          </div>

          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card-2.png">
            </div>

            <div class="Cards_Item_Titulo">
              Atendimento
            </div>

            <div class="Cards_Item_Texto">
              Suporte exclusivo, por canais digitais ou telefone, à sua disposição
            </div>
          </div>

          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card10.png">
            </div>

            <div class="Cards_Item_Titulo">
              Tecnologia
            </div>

            <div class="Cards_Item_Texto">
              Diversos serviços e produtos úteis para você acessar via Site ou App
            </div>
          </div>

          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card11.png">
            </div>

            <div class="Cards_Item_Titulo">
              Valorização
            </div>

            <div class="Cards_Item_Texto">
              Serviços personalizados para valorizar o seu patrimônio
            </div>
          </div>

          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card9.png">
            </div>

            <div class="Cards_Item_Titulo">
              Liberdade
            </div>

            <div class="Cards_Item_Texto">
              Escolha o plano de acordo com as necessidades do seu condomínio
            </div>
          </div>

          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card12.png">
            </div>

            <div class="Cards_Item_Titulo">
              Transparência
            </div>

            <div class="Cards_Item_Texto">
              Informações disponíveis para o acesso de gestores e condôminos
            </div>
          </div>

          <div class="Container_Cards_Item">
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card6.png">
            </div>

            <div class="Cards_Item_Titulo">
              Segurança
            </div>

            <div class="Cards_Item_Texto">
              Plataforma segura para a proteção dos seus dados
            </div>
          </div>
        </div>
      </div>

      <div class="MobileAboutCards">

        <ngx-slick-carousel class="Sobre_Container_Cards" [config]="cardSlickConfig">
          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card7.png">
            </div>

            <div class="Cards_Item_Titulo">
              Comodidade
            </div>

            <div class="Cards_Item_Texto">
              Tudo o que você precisa para o seu condomínio em um só lugar
            </div>
          </div>

          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card4.png">
            </div>

            <div class="Cards_Item_Titulo">
              Agilidade
            </div>

            <div class="Cards_Item_Texto">
              Processos totalmente digitalizados com menos burocracia
            </div>
          </div>

          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card-2.png">
            </div>

            <div class="Cards_Item_Titulo">
              Atendimento
            </div>

            <div class="Cards_Item_Texto">
              Suporte exclusivo, por canais digitais ou telefone, à sua disposição
            </div>
          </div>

          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card10.png">
            </div>

            <div class="Cards_Item_Titulo">
              Tecnologia
            </div>

            <div class="Cards_Item_Texto">
              Diversos serviços e produtos úteis para você acessar via Site ou App
            </div>
          </div>

          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card11.png">
            </div>

            <div class="Cards_Item_Titulo">
              Valorização
            </div>

            <div class="Cards_Item_Texto">
              Serviços personalizados para valorizar o seu patrimônio
            </div>
          </div>

          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card9.png">
            </div>

            <div class="Cards_Item_Titulo">
              Liberdade
            </div>

            <div class="Cards_Item_Texto">
              Escolha o plano de acordo com as necessidades do seu condomínio
            </div>
          </div>

          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card12.png">
            </div>

            <div class="Cards_Item_Titulo">
              Transparência
            </div>

            <div class="Cards_Item_Texto">
              Informações disponíveis para o acesso de gestores e condôminos
            </div>
          </div>

          <div class="Container_Cards_Item" ngxSlickItem>
            <div class="Cards_Item_Icone">
              <img src="assets/img/sobre-card6.png">
            </div>

            <div class="Cards_Item_Titulo">
              Segurança
            </div>

            <div class="Cards_Item_Texto">
              Plataforma segura para a proteção dos seus dados
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>

    <div class="LandingAPSA_Sobre_Background">
    </div>
  </div>

  <div class="LandingAPSA_Planos" id="Planos">
    <div class="LandingAPSA_Planos_Container">
      <div class="Planos_Container_Titulo">
        Planos
      </div>
      <div class="Planos_Container_Subtitulo">
        Tenha todos os serviços de gestão condominial ao seu alcance.
        Dois planos para atender plenamente as suas necessidades com eficiência
        e praticidade. Seja para as necessidades básicas ou mais ainda com o controle
        da folha de pagamento. <b>Você escolhe o que é melhor para o seu condomínio!</b>
      </div>

      <div class="Planos_Container_Content">

        <ngx-slick-carousel class="Container_Content_Cards __is-planos" [config]="Container_Content_Cards" #carousel>
          <div class="Content_Cards_Item" ngxSlickItem *ngFor="let plano of listaPlanos; index as i">
            <div class="Cards_Item_Icone">
              <img src="assets/img/plano {{i+1}}.png">
            </div>

            <div class="Cards_Item_Titulo">
              {{plano.titulo}}
            </div>

            <!-- <div class="Cards_Item_Texto">
              {{plano.descricao}}
            </div> -->

            <!-- <div class="Cards_Item_Button" (click)='handleSaibaMaisSobrePlano(plano, i)'>
              Saiba mais
            </div> -->

            <div class="Cards_Item_Button" (click)='openComparativeModal(i)'>
              Saiba mais
            </div>
          </div>

        </ngx-slick-carousel>

        <div class="Container_Content_Simulacao">
          <div class="Content_Simulacao_Titulo">
            Quer aderir à Gestão Condominial Digital Apsa?
          </div>

          <!-- <div class="Content_Simulacao_Texto">
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.Occaecat cupidatat
            non.
          </div> -->

          <div class="Container_Content_Button __is-blue" (click)='simuleSaibaComo()'>
            <i class="fas fa-caret-left"></i>
            <div class="Component_ButtonCreative PlanSimulateButton">
              Simule aqui e saiba como
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="LandingAPSA_Planos_ContainerNovo">
      <div class="Planos_ContainerNovo_Header">
        <div class="ContainerNovo_Header_Principal">
          <div class="Header_Principal_Titulo">
            Planos para você
          </div>
          <div class="Header_Principal_Descricao">
            O Condomínio Digital Apsa é a forma de administração mais eficiente para
            o seu condomínio. Nossos planos combinam o que há de melhor em controle administrativo, financeiro e
            gerencial, além da possibilidade de gestão
            de funcionários e folha de pagamento.

            Conheça a tradição da consultoria condominial Apsa aliada à uma
            experiência completamente digital, inovadora, inclusiva e participativa
            para você e seus condôminos.
          </div>
        </div>
        <div class="ContainerNovo_Header_Secundario">
          <div class="Header_Secundario_Titulo">
            Quer aderir a Gestão Condominial Digital Apsa?
          </div>
          <div class="Header_Secundario_Button">
            <div _ngcontent-eiq-c58="" class="Container_Content_Button __is-blue"><i _ngcontent-eiq-c58=""
                class="fas fa-caret-left"></i>
              <div _ngcontent-eiq-c58="" class="Component_ButtonCreative"> Conheça nossos planos </div>
            </div>
          </div>
        </div>
      </div>

      <div class="Planos_ContainerNovo_Tabela">
        <table width="100%" class="ContainerNovo_Tabela_Item">
          <tr class="Tabela_Item_Row">
            <td width="50%">

            </td>
            <td width="25%">
              <img src="assets/img/plano 1.png">
            </td>
            <td width="25%">
              <div class="Badge_MaisVendido">
                Mais vendido!
              </div>
              <img src="assets/img/plano 2.png">
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Titulo">
            <td width="50%">
              Serviço
            </td>
            <td width="25%">
              PLANO DIGITAL BÁSICO
            </td>
            <td width="25%">
              PLANO DIGITAL COMPLETO
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Atendimento às demandas administrativas
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulClaro">
            <td width="50%">
              <span>
                Cotas condominiais e boletos digitais
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Realização de pagamentos
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulClaro">
            <td width="50%">
              <span>
                Controle orçamentário
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Extratos, relatórios e prestações de contas interativos
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulClaro">
            <td width="50%">
              <span>
                Digitalização de documentos condominiais
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Agendamento e reservas de espaço
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulClaro">
            <td width="50%">
              <span>
                Mural de avisos
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Livro de ocorrências
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulClaro">
            <td width="50%">
              <span>
                Cotação online de serviços
              </span>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Admissão de funcionários
              </span>
            </td>
            <td width="25%">

            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulClaro">
            <td width="50%">
              <span>
                Controle de ponto e horas extras
              </span>
            </td>
            <td width="25%">

            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Controle e simulação de férias
              </span>
            </td>
            <td width="25%">

            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulClaro">
            <td width="50%">
              <span>
                Processamento de folha de pagamento
              </span>
            </td>
            <td width="25%">

            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic __is-AzulEscuro">
            <td width="50%">
              <span>
                Simulação de rescisões
              </span>
            </td>
            <td width="25%">

            </td>
            <td width="25%">
              <i class="fas fa-check"></i>
            </td>
          </tr>

          <tr class="Tabela_Item_Row __is-Basic">
            <td width="50%">
              <span>

              </span>
            </td>
            <td width="25%" class="__is-button">
              <div class="Container_Content_Button __is-orange" (click)="contratarEssencial()">
                <i class="fas fa-caret-left"></i>
                <div class="Component_ButtonCreative">Contrate agora</div>
              </div>
            </td>
            <td width="25%" class="__is-button">
              <div class="Container_Content_Button __is-orange" (click)="contratarCompleto()"><i
                  class="fas fa-caret-left"></i>
                <div class="Component_ButtonCreative">Contrate agora</div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <ngx-slick-carousel class="Planos_ContainerNovo_TabelaMobile" [config]="Planos_ContainerNovo_TabelaMobile"
        #carousel>
        <div class="ContainerNovo_TabelaMobile_Item" ngxSlickItem>
          <div class="TabelaMobile_Item_Header">
            <div class="Item_Header_Titulo">
              PLANO DIGITAL BÁSICO
            </div>
            <div class="Item_Header_Icone">
              <img src="assets/img/plano_1_white.png">
            </div>
          </div>
          <div class="TabelaMobile_Item_Servico">
            Serviço
          </div>
          <div class="TabelaMobile_Item_ServicosContainer">
            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Atendimento às demandas administrativas</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Cotas condominiais e boletos digitais</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Realização de pagamentos</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Controle orçamentário</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Extratos, relatórios e prestações de contas interativos</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Digitalização de documentos condominiais</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Agendamento e reservas de espaço</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Mural de avisos</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Livro de ocorrências</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Cotação online de serviços</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Admissão de funcionários</span>
              <div class="ServicosContainer_Item_Icone">

              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Controle de ponto e horas extras</span>
              <div class="ServicosContainer_Item_Icone">

              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Controle e simulação de férias</span>
              <div class="ServicosContainer_Item_Icone">

              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Processamento de folha de pagamento</span>
              <div class="ServicosContainer_Item_Icone">

              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Simulação de rescisões</span>
              <div class="ServicosContainer_Item_Icone">

              </div>
            </div>

            <div class="Item_ServicosContainer_Button">
              <div class="Container_Content_Button __is-orange" (click)="contratarEssencial()">
                <i class="fas fa-caret-left"></i>
                <div class="Component_ButtonCreative"> Contratar</div>
              </div>
            </div>
          </div>
        </div>

        <div class="ContainerNovo_TabelaMobile_Item" ngxSlickItem>
          <div class="TabelaMobile_Item_Header">
            <div class="Item_Header_Titulo">
              PLANO DIGITAL COMPLETO
            </div>
            <div class="Item_Header_Icone">
              <img src="assets/img/plano_2_white.png">
            </div>
          </div>
          <div class="TabelaMobile_Item_Servico">
            Serviço
          </div>
          <div class="TabelaMobile_Item_ServicosContainer">
            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Atendimento às demandas administrativas</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Cotas condominiais e boletos digitais</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Realização de pagamentos</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Controle orçamentário</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Extratos, relatórios e prestações de contas interativos</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Digitalização de documentos condominiais</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Agendamento e reservas de espaço</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Mural de avisos</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Livro de ocorrências</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Cotação online de serviços</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Admissão de funcionários</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Controle de ponto e horas extras</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Controle e simulação de férias</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulClaro">
              <span>Processamento de folha de pagamento</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>

            <div class="Item_ServicosContainer_Item __AzulEscuro">
              <span>Simulação de rescisões</span>
              <div class="ServicosContainer_Item_Icone">
                <i class="fas fa-check"></i>
              </div>
            </div>
            <div class="Item_ServicosContainer_Button">
              <div class="Container_Content_Button __is-orange" (click)="contratarCompleto()">
                <i class="fas fa-caret-left"></i>
                <div class="Component_ButtonCreative"> Contratar</div>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>

    <div class="LandingAPSA_Planos_Check"></div>
  </div>

  <div class="LandingAPSA_Servicos" id="Servicos">
    <div class="LandingAPSA_Servicos_Container">
      <div class="Servicos_Container_Content">
        <div class="Container_Content_Titulo">
          <b>Veja como é fácil contratar a
            gestão condominial digital Apsa.</b>
          <span>Em apenas 5 passos, o controle do seu condomínio nas suas mãos.</span>
        </div>

        <div class="Container_Content_Bloco">
          <div class="Content_Bloco_Item">
            <div class="Bloco_Item_Numero">
              1
            </div>
            <div class="Bloco_Item_Texto">
              Digite os seus dados de usuário;
            </div>
          </div>

          <div class="Content_Bloco_Item">
            <div class="Bloco_Item_Numero">
              2
            </div>
            <div class="Bloco_Item_Texto">
              Simula os valores, informando o número de unidades e funcionários;
            </div>
          </div>

          <div class="Content_Bloco_Item">
            <div class="Bloco_Item_Numero">
              3
            </div>
            <div class="Bloco_Item_Texto">
              Confirme e complemente os dados de contratação;
            </div>
          </div>

          <div class="Content_Bloco_Item">
            <div class="Bloco_Item_Numero">
              4
            </div>
            <div class="Bloco_Item_Texto">
              Em seguida, enviaremos o seu contrato para aprovação. A efetivação é digital!
            </div>
          </div>

          <div class="Content_Bloco_Item">
            <div class="Bloco_Item_Numero">
              5
            </div>
            <div class="Bloco_Item_Texto">
              Envie os documentos comprobatórios para iniciamos a implantação dos serviços em seu condomínio!
            </div>
          </div>
          <!-- <div class="Container_Content_Button __is-blue">
            <i class="fas fa-caret-left"></i>
            <div class="Component_ButtonCreative">
              Saiba mais
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="LandingAPSA_Depoimentos" id="Depoimentos">
    <div class="LandingAPSA_Depoimentos_Container">
      <div class="Depoimentos_Container_Content">
        <div class="Container_Content_Titulo">
          Depoimentos
        </div>

        <ngx-slick-carousel class="Container_Content_Cards" [config]="SlickDepoimentos">
          <div class="Content_Cards_Row" ngxSlickItem>
            <div class="Cards_Row_Item">
              <div class="Row_Item_LineOne">
                <div class="Item_LineOne_Titulo">
                  Carlos Alberto Campos
                </div>

                <div class="Item_LineOne_Estrelas">
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                </div>
              </div>

              <div class="Row_Item_Texto">
                <span>Síndico de um condomínio administrado pela Apsa</span>
                Foi desafiador usar uma nova ferramenta (app Apsa). Mas essa percepção mudou quando percebi que com o app tenho mais agilidade e segurança do que no método tradicional.
                <a href="https://www.youtube.com/watch?v=m84uhnyQrfc" target="_blank">Ver depoimento completo.</a>
              </div>
            </div>
          </div>

          <div class="Content_Cards_Row" ngxSlickItem>
            <div class="Cards_Row_Item">
              <div class="Row_Item_LineOne">
                <div class="Item_LineOne_Titulo">
                  Fábio Teixeira
                </div>

                <div class="Item_LineOne_Estrelas">
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                </div>
              </div>

              <div class="Row_Item_Texto">
                <span>Síndico GPU parceiro da Apsa</span>
                A ferramenta envio de pagamentos do app Apsa tem facilitado muito a vida dos síndicos, principalmente durante o período da pandemia.
                <a href="https://www.youtube.com/watch?v=b5Cx6JMU_m4" target="_blank">Ver depoimento completo</a>
              </div>
            </div>
          </div>

          <div class="Content_Cards_Row" ngxSlickItem>
            <div class="Cards_Row_Item">
              <div class="Row_Item_LineOne">
                <div class="Item_LineOne_Titulo">
                  Celina Rosa
                </div>

                <div class="Item_LineOne_Estrelas">
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                </div>
              </div>

              <div class="Row_Item_Texto">
                <span>Proprietária de imóvel</span>
                O atendimento da Apsa é ótimo. Dá pra ver que eles mantêm uma tradição de cuidado com o cliente estão sempre inovando.
                <a href="https://www.youtube.com/watch?v=mPCPtcpuzm4&t=1s" target="_blank">Ver depoimento completo</a>
              </div>
            </div>
          </div>

          <div class="Content_Cards_Row" ngxSlickItem>
            <div class="Cards_Row_Item">
              <div class="Row_Item_LineOne">
                <div class="Item_LineOne_Titulo">
                  Rafaela Blanco
                </div>

                <div class="Item_LineOne_Estrelas">
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                </div>
              </div>

              <div class="Row_Item_Texto">
                <span>Proprietária de imóvel</span>
                Eu indico a Apsa para todos os meus amigos. O atendimento é super rápido e eficaz.
                <a href="https://www.youtube.com/watch?v=7e-x9gySmaU&t=2s" target="_blank">Ver depoimento completo</a>
              </div>
            </div>
          </div>

          <div class="Content_Cards_Row" ngxSlickItem>
            <div class="Cards_Row_Item">
              <div class="Row_Item_LineOne">
                <div class="Item_LineOne_Titulo">
                  Flávia Barros
                </div>

                <div class="Item_LineOne_Estrelas">
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                  <i class="fas fa-star __is-active"></i>
                </div>
              </div>

              <div class="Row_Item_Texto">
                <span>Proprietária de imóvel</span>
                Após fazer pesquisa de mercado, os melhores depoimento foram o da Apsa tanto em aluguel quanto em
                administração de condomínios. Estou gostando bastante da experiência de ser cliente Apsa
                <a href="https://www.youtube.com/watch?v=92R_P0UXc_4&t=6s" target="_blank">Ver depoimento completo</a>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>

  <div class="LandingAPSA_ApsaNaMidia">
    <div class="LandingAPSA_ApsaNaMidia_Container">
      <div class="ApsaNaMidia_Container_Titulo">
        Apsa na Mídia
      </div>
      <ngx-slick-carousel class="ApsaNaMidia_Container_Content" [config]="ApsaNaMidia_Container_Content">
        <div class="Container_Content_Item" ngxSlickItem>
          <div class="Content_Item_Imagem">
            <img src="assets/img/apsa-na-midia/112.jpg">
          </div>
          <div class="Content_Item_Texto">
            <div class="Item_Texto_Titulo">
              Síndicos em ação
            </div>
            <div class="Item_Texto_Descricao">
              <a href="https://clipping.cservice.com.br/cliente/viewmateria.htm?materiaId%3d49111410%26canalId%3d63397%26clienteId%3dZ4ZIy5gkUZ4%3d%26newsletterId%3dqBIXSBa1wm8%3d"
                target="_blank">Ver matéria completa</a>
            </div>
          </div>
        </div>
        <div class="Container_Content_Item" ngxSlickItem>
          <div class="Content_Item_Imagem">
            <img src="assets/img/apsa-na-midia/20920.jpg">
          </div>
          <div class="Content_Item_Texto">
            <div class="Item_Texto_Titulo">
              Apsa investe em plataforma que que acaba com a burocracia – Valor econômico
            </div>
            <div class="Item_Texto_Descricao">
              <a href="https://valor.globo.com/empresas/noticia/2020/02/03/tecnologia-ajuda-imobiliarias-a-reduzirem-custos-e-atrair-clientes.ghtml"
                target="_blank">
                Ver matéria completa
              </a>
            </div>
          </div>
        </div>
        <div class="Container_Content_Item" ngxSlickItem>
          <div class="Content_Item_Imagem">
            <img src="assets/img/apsa-na-midia/428385-PDYRGB-67.jpg">
          </div>
          <div class="Content_Item_Texto">
            <div class="Item_Texto_Titulo">
              Apsa lança duas plataformas para aluguéis de imóveis – Estadão
            </div>
            <div class="Item_Texto_Descricao">
              <a href="https://economia.estadao.com.br/blogs/coluna-do-broad/apsa-lanca-duas-plataformas-para-alugueis-de-imoveis/"
                target="_blank">Ver matéria completa</a>
            </div>
          </div>
        </div>
        <div class="Container_Content_Item" ngxSlickItem>
          <div class="Content_Item_Imagem">
            <img src="assets/img/apsa-na-midia/3522983.jpg">
          </div>
          <div class="Content_Item_Texto">
            <div class="Item_Texto_Titulo">
              Síndicos no poder projeto de lei prever respaldo ações emergenciais em condomínios contra coronavírus.
            </div>
            <div class="Item_Texto_Descricao">
              <a href="https://oglobo.globo.com/rio/sindicos-no-poder-projeto-de-lei-preve-respaldo-acoes-emergenciais-em-condominios-contra-coronavirus-24351426"
                target="_blank">Ver matéria completa</a>
            </div>
          </div>
        </div>

        <div class="Container_Content_Item" ngxSlickItem>
          <div class="Content_Item_Imagem">
            <img src="assets/img/apsa-na-midia/77926.jpg">
          </div>
          <div class="Content_Item_Texto">
            <div class="Item_Texto_Titulo">
              Inadimplência em Condomínios
            </div>
            <div class="Item_Texto_Descricao">
              <a href="https://www.youtube.com/watch?v=70nh29KhEaI" target="_blank">Ver matéria completa</a>
            </div>
          </div>
        </div>
        <div class="Container_Content_Item" ngxSlickItem>
          <div class="Content_Item_Imagem">
            <img src="assets/img/apsa-na-midia/541.jpg">
          </div>
          <div class="Content_Item_Texto">
            <div class="Item_Texto_Titulo">
              Dicas para o isolamento social
            </div>
            <div class="Item_Texto_Descricao">
              <a href="https://www.youtube.com/watch?v=4RPATMG86mA" target="_blank">
                Ver matéria completa
              </a>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>

  <div class="LandingAPSA_Contato" id="Contato">
    <div class="LandingAPSA_Contato_Container">
      <div class="Contato_Container_Content">
        <div class="Container_Content_Conteudo">
          <div class="Content_Conteudo_Titulo">
            <b>Administre seu condomínio online,</b> com economia e SEM BUROCRACIA!
          </div>

          <div class="Content_Conteudo_ButtonMobile">
            <div class="Container_Content_Button __is-contato-faleconosco __is-blue" (click)="handleFaleConoscoClick()">
              <i class="fas fa-caret-left"></i>

              <div class="Component_ButtonCreative __is-blue">
                Fale conosco
              </div>
            </div>
          </div>

          <div class="Content_Conteudo_Texto">
            Para mais informações sobre o Condomínio Digital e os nossos planos, <b>preencha
              o formulário ao lado e clique em ENVIAR!</b>
          </div>

          <div class="CallToFAQ __is-desktop">
            <div class="CallToFAQIconContainer">
              <img src="assets/img/discuss-issue.svg">
            </div>

            <p class="CallToFAQText">
              Você pode acessar também nossa <b>Central de Ajuda</b> para
              mais informações! <a routerLink="/faq">Clique aqui!</a>
            </p>
          </div>
        </div>

        <div class="Container_Content_Form" [ngClass]="{ '__is-active': isContactFormMobileOpen }">
          <form class="Content_Form_Item" [formGroup]="form" (keyup.enter)='enviarContato()'>
            <div class="Form_Item_Content">
              <input placeholder="Nome" formControlName="nome" type="text">
            </div>

            <div class="Form_Item_Content">
              <input placeholder="Email" formControlName="email" type="email">
            </div>

            <div class="Form_Item_Content">
              <input placeholder="Telefone" formControlName="telefone" type="tel"
                mask='(00) 0000-0000||(00) 0 0000-0000'>
            </div>

            <div class="Form_Item_Content">
              <textarea placeholder="Mensagem" formControlName="mensagem" type='text'></textarea>
            </div>

            <div class="Form_Item_Content __is-btnenviar">
              <div class="Container_Content_Button __is-blue" [ngClass]="{'__is-disable': desabilitaBotao()}">
                <i class="fas fa-caret-left"></i>

                <div class="Component_ButtonCreative" (click)='enviarContato()'>
                  Enviar
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="CallToFAQ __is-mobile">
          <div class="CallToFAQIconContainer">
            <img src="assets/img/discuss-issue.svg">
          </div>

          <p class="CallToFAQText">
            Você pode acessar também nossa <b>Central de Ajuda</b> para
            mais informações! <a routerLink="/faq">Clique aqui!</a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="LandingAPSA_Footer">
    <div class="LandingAPSA_Footer_Container">
      <div class="Footer_Container_RowOne">
        <div class="Container_RowOne_Logotipo">
          <img src="assets/img/logotipo-apsa-azul-completo.svg">
        </div>
      </div>

      <div class="Footer_Container_RowTwo">
        <div class="Footer_Container_Dados">
          <div class="Container_Dados_Row">
            <div class="Dados_Row_Item">
              <span class="Container_Dados_Item __Is-Destaque">Endereço - Matriz: </span>
            </div>

            <div class="Dados_Row_Item">
              <span class="Container_Dados_Item">Travessa do Ouvidor, 32 - Centro</span>
            </div>
          </div>

          <div class="Container_Dados_Row">
            <div class="Dados_Row_Item">
              <span class="Container_Dados_Item">Rio de Janeiro RJ</span>
            </div>

            <div class="Dados_Row_Item">
              <span class="Container_Dados_Item __Is-Destaque">Telefone: </span>
              <span class="Container_Dados_Item">(21) 3233-3000</span>
            </div>
          </div>
        </div>

        <div class="Container_RowOne_RedesSociais">
          <div class="RowOne_RedesSociais_Titulo">
            @apsaoficial na rede!
          </div>
          
          <div class="RowOne_RedesSociais_RedesSociaisItens">
            <a href="https://www.facebook.com/APSAOficial/" target="_blank">
              <div class="RowOne_RedesSociais_Item">
                <i class="fab fa-facebook-f"></i>
              </div>
            </a>
  
            <a href="https://www.linkedin.com/company/apsaviverbemempropriedadesurbanas/" target="_blank">
              <div class="RowOne_RedesSociais_Item">
                <i class="fab fa-linkedin"></i>
              </div>
            </a>
  
            <a href="https://www.instagram.com/oficialapsa/?hl=pt-br" target="_blank">
              <div class="RowOne_RedesSociais_Item">
                <i class="fab fa-instagram"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="LandingAPSA_Footer_Copyright">
      <div class="Footer_Copyright_Container">
        <span>©All Rights Reserved.</span>

        <div class="Credits">
          <div class="Credits_Text">
            Desenvolvimento
          </div>

          <div class="Credits_Marlin">
            <a href="http://www.marlin.com.br" target="_blank">
              <img src="assets/img/LogoMarlin.png" />
            </a>
          </div>

          <span class="Credits_Separator"></span>

          <div class="Credits_Text">
            Design
          </div>

          <div class="Credits_Imaginatto">
            <a href="http://www.imaginatto.com.br/" target="_blank">
              <img src="../.././../assets/img/imaginatto.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="MenuMobile" [ngClass]="{ '__is-active': isMenuMobileOpen }">
  <div class="MenuMobile_Content">
    <div class="MenuMobile_Content_LineOne">
      <div class="Content_LineOne_Name">
        Menu
      </div>

      <div class="Content_LineOne_Menu" (click)="closeMenuMobile()">
        <i class="fas fa-times"></i>
      </div>
    </div>

    <ul>
      <a href="#Sobre" class="Scroll RemoveMobileLink" (click)="handleScrollClick($event); closeMenuMobile()">
        <li>Sobre</li>
      </a>

      <a href="#Planos" class="Scroll RemoveMobileLink" (click)="handleScrollClick($event); closeMenuMobile()">
        <li>Planos</li>
      </a>

      <a routerLink="/simulacao">
        <li>Simule</li>
      </a>

      <a href="#Servicos" class="Scroll RemoveMobileLink" (click)="handleScrollClick($event); closeMenuMobile()">
        <li>Serviços</li>
      </a>

      <a href="#Depoimentos" class="Scroll RemoveMobileLink" (click)="handleScrollClick($event); closeMenuMobile()">
        <li>Depoimentos</li>
      </a>

      <a href="#Contato" class="Scroll RemoveMobileLink" (click)="handleScrollClick($event); closeMenuMobile()">
        <li>Contatos</li>
      </a>
    </ul>
  </div>
</div>

<popup [isOpen]="isPopupOpen" (onClose)="isPopupOpen = false">
  <div id="container"></div>
</popup>

<app-ligthboxes-default id="custom-generico" className="__is-open">
</app-ligthboxes-default>

<app-plan-popup [isOpen]="isPlanPopupOpen" [slideToFocus]="selectedPlanIndex" (onClose)="closePlanPopup()"
  (onSimulate)="handlePlanoSelecionadoNoPopup($event)"></app-plan-popup>

<!-- <app-plan-compartive-popup [isOpen]="isComparativeModalOpen" (onClose)="closeComparativeModal()"
  [planoEssencial]="listaPlanos[0]" [planoCompleto]="listaPlanos[1]"></app-plan-compartive-popup>

<app-plan-compartive-mobile-popup [isOpen]="isMobileComparativeModalOpen" (onClose)="closeMobileComparativeModal()"
  [slideToFocus]="selectedPlanIndex" [planoEssencial]="listaPlanos[0]" [planoCompleto]="listaPlanos[1]">
</app-plan-compartive-mobile-popup> -->