<footer class="LandingAPSA_Footer">
  <div class="LandingAPSA_Footer_Container">
    <div class="Footer_Container_RowOne">
      <div class="Container_RowOne_Logotipo">
        <img src="assets/img/logotipo-apsa-azul.png">
      </div>

      <div class="Container_RowOne_RedesSociais">
        <a href="https://www.facebook.com/APSAOficial/" target="_blank">
          <div class="RowOne_RedesSociais_Item">
            <i class="fab fa-facebook-f"></i>
          </div>
        </a>

        <a href="#" target="_blank">
          <div class="RowOne_RedesSociais_Item">
            <i class="fab fa-google-plus-g"></i>
          </div>
        </a>

        <a href="https://www.instagram.com/oficialapsa/?hl=pt-br" target="_blank">
          <div class="RowOne_RedesSociais_Item">
            <i class="fab fa-instagram"></i>
          </div>
        </a>
      </div>
    </div>

    <div class="Footer_Container_Dados">
      <div class="Container_Dados_Item __Is-Destaque">Endereço - Matriz</div>
      <div class="Container_Dados_Item">Travessa do Ouvidor, 32 - Centro</div>
      <div class="Container_Dados_Item">Rio de Janeiro RJ</div>
      <div class="Container_Dados_Item __Is-Destaque">Telefone</div>
      <div class="Container_Dados_Item">(21) 3233-3000</div>
    </div>
  </div>

  <div class="LandingAPSA_Footer_Copyright">
    <div class="Footer_Copyright_Container">
      <span>©All Rights Reserved.</span>

      <div class="Credits">
        <div class="Credits_Text">
          Desenvolvimento
        </div>

        <div class="Credits_Marlin">
          <a href="http://www.marlin.com.br" target="_blank">
            <img src="assets/img/LogoMarlin.png" />
          </a>
        </div>

        <span class="Credits_Separator"></span>

        <div class="Credits_Text">
          Design
        </div>

        <div class="Credits_Imaginatto">
          <a href="http://www.imaginatto.com.br/" target="_blank">
            <img src="../.././../assets/img/imaginatto.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>