import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LightBoxesService } from '../components/lightboxes';
import { StateService } from '../services/stateService';

@Injectable()
export class Interceptor401 implements HttpInterceptor {

  // public requisicao: HttpRequest<any>;

  constructor(private router: Router, private lightBoxesService: LightBoxesService, private stateService: StateService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.requisicao = request
    return next.handle(request).pipe(
      tap(
        () => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              //console.log("VEIO LOGIN EXPIRADO 4")
              // this.lightBoxesService.open(
              //     'custom-generico', {
              //         title:'',
              //         text: 'Ocorreu um erro ao prosseguir. Tente novamente.'
              //     }, 5000
              // )
              sessionStorage.removeItem('tokenApi')
              this.stateService.getTokenApiCondominioDigital();

              return;
              // return next.handle(this.requisicao);
            }
          }
        }
      )
    );
  }
}
