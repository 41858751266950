import { Component, OnInit } from '@angular/core';
import { StateService } from './shared/services/stateService';
import { LightBoxesService } from './shared/components/lightboxes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

  constructor(
    private stateService: StateService,
    private lightboxService: LightBoxesService,
  ) { }

  title = 'cnd-contratacao';

  ngOnInit(): void {
    // this.getToken()
  }

  async getToken(){
    await this.stateService.getTokenApiCondominioDigital();

  }

  scrollTop() {
    window.scroll(0,0)
  }

}
