import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GetPlanosDTO } from 'src/app/modules/home/services/DTO/GetPlanos.DTO';

@Component({
  selector: 'app-plan-compartive-popup',
  templateUrl: './plan-compartive-popup.component.html',
  styleUrls: ['./plan-compartive-popup.component.scss']
})
export class PlanCompartivePopupComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() planoEssencial: GetPlanosDTO;
  @Input() planoCompleto: GetPlanosDTO;
  @Output() onClose = new EventEmitter();

  constructor(public router: Router) { }

  emitClose() {
    this.onClose.emit();
  }

  contratarEssencial() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', 'assets/img/plano 1.png');
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(this.planoEssencial));

    this.router.navigate(['plano']);
  }

  contratarCompleto() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', 'assets/img/plano 2.png');
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(this.planoCompleto));

    this.router.navigate(['plano']);
  }

  ngOnInit(): void {
  }
}
