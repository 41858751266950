import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StateService } from 'src/app/shared/services/stateService';
import { environment } from 'src/environments/environment';
import { CepDTO } from './DTO/Cep.DTO';
import { CNPJDTO } from './DTO/CNPJ.DTO';

@Injectable({
  providedIn: 'root'
})
export class ContratacaoService {

  private url: string;
  private methods;

  constructor(
    private http: HttpClient,
    private stateService: StateService
  ) {
    this.url = environment.apiContratacao.host
    this.methods = environment.apiContratacao.endpoints.contratacao
   }

   private getHeader(){
     return new HttpHeaders({
       'Content-Type' : 'application/json',
       'Authorization' : `Bearer ${this.stateService.getTokenApiCondominioDigital()}`
     })
   }

   getCNPJ(cnpj){
     const parametros = new HttpParams()
      .set('cnpj', cnpj)

    return this.http.get<CNPJDTO>(`${this.url + this.methods.getCNPJ}`,
      {headers:this.getHeader(), params:parametros, observe: 'response'})
   }

   getCEP(cep){
     const parametros = new HttpParams()
      .set('cep', cep)

    return this.http.get<CepDTO>(`${this.url + this.methods.getCEP}`,
      {headers:this.getHeader(), params:parametros})
   }

   criaContrato(obj){

    return this.http.post(`${this.url + this.methods.criaContrato}`, obj, {headers:this.getHeader()})

   }

   checaContrato(chave:string){
     const parametros = new HttpParams()
      .set('chaveDoc', chave)

    return this.http.get<boolean>(`${this.url +this.methods.checaAssinatura}`, 
      {headers:this.getHeader(), params:parametros})
   }
}
