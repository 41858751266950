import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ClickSignService } from './services/click-sign.service';

import { PopupComponent } from './components/popup/popup.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';

const lightBoxesComponents = [DefaultLightBoxesComponent, MuralCadastradoComponent, ImageWithToggleAndOkButton];

import {
  DefaultLightBoxesComponent,
  MuralCadastradoComponent,
  // TextInputWithConfirmationComponent,
  ImageWithToggleAndOkButton
} from './components/lightboxes/index';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenInterceptor } from './components/loading-screen/loading-screen.interceptor';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingScreenService } from './components/loading-screen/loading-screen.service';
import { Interceptor401 } from './interceptors/Interceptor401';
import { PlanComponent } from './components/plan/plan.component'
import { PlanPopupComponent } from './components/plan-popup/plan-popup.component'
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PlanCompartivePopupComponent } from './components/plan-compartive-popup/plan-compartive-popup.component';
import { PlanCompartiveMobilePopupComponent } from './components/plan-compartive-mobile-popup/plan-compartive-mobile-popup.component';

@NgModule({
  declarations: [
     ...lightBoxesComponents,
    LoadingScreenComponent,
    PopupComponent,
    HeaderComponent,
    FooterComponent,
    MenuMobileComponent,
    PlanPopupComponent,
    PlanComponent,
    PlanCompartivePopupComponent,
    PlanCompartiveMobilePopupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SlickCarouselModule,
  ],
  exports: [
    ...lightBoxesComponents,
    LoadingScreenComponent,
    PopupComponent,
    HeaderComponent,
    FooterComponent,
    MenuMobileComponent,
    PlanPopupComponent,
    PlanComponent,
    PlanCompartivePopupComponent,
    PlanCompartiveMobilePopupComponent,
  ],
  providers: [
    ClickSignService,
    LoadingScreenInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },{
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor401,
      multi: true
    },
    LoadingScreenService,
  ]
})
export class SharedModule { }
