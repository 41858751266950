import { Component, OnInit } from '@angular/core';
import { MenuMobileService } from '../../services/menu-mobile.service';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {

  constructor(
    public menuMobileService: MenuMobileService,
  ) { }

  ngOnInit(): void {
  }

  isMobileMenuOpen() {
    return this.menuMobileService.isOpen();
  }

  closeMenuMobile() {
    this.menuMobileService.close();
  }
}
