<header class="HeaderComponent">
  <div class="HeaderComponent_Container">
    <div class="LogoArea">
      <img class="Logo_Desktop" src="assets/img/logotipo-apsa.png" (click)='redirecionaHome()'>
      <img class="Logo_Mobile" src="assets/img/logotipo-apsa-azul.png" (click)='redirecionaHome()'>
    </div>
  
    <!-- <div class="HeaderComponent_Container_MenuDesktop">
      <ul>
        <a routerLink="/" fragment="Sobre" class="Scroll RemoveMobileLink">
          <li>Sobre</li>
        </a>
  
        <a routerLink="/" fragment="Planos" class="Scroll RemoveMobileLink">
          <li>Planos</li>
        </a>

        <a routerLink="/" fragment="Depoimentos" class="Scroll RemoveMobileLink">
          <li>Depoimentos</li>
        </a>

        <a routerLink="/faq">
          <li>Ajuda</li>
        </a>
  
        <a routerLink="/" fragment="Contato" class="Scroll RemoveMobileLink">
          <li>Contato</li>
        </a>
      </ul>
    </div> -->

    <!-- <div class="MenuArea" (click)="handleMenuClick()">
      <i class="fas fa-bars"></i>
    </div> -->
  </div>
</header>

<app-menu-mobile></app-menu-mobile>