<div class="Page PlanoPage">
  <div class="PlanoPage_BlueGradient"></div>
  <div class="PagePlano_ModalNaoAtendido" [ngClass]="{'__is-active': !this.cepValido}">
    <div class="PagePlano_ModalNaoAtendido_Container">
      <div class="ModalNaoAtendido_Container_Close" (click)='resetaCEP()'>
        <i class="fas fa-times"></i>
      </div>
      <div class="ModalNaoAtendido_Container_Titulo">
        Estamos em expansão...
      </div>
      <div class="ModalNaoAtendido_Container_Texto">
        No momento ainda não atendemos com o formato de condomínio digital na sua área. <br /><b>Não se preocupe,
          entraremos em contato</b> assim que sua região estiver habilitada para os nossos serviços digitais.
      </div>
      <div class="ModalNaoAtendido_Container_Button">
        <div class="Container_Content_Button __is-orange" (click)='resetaCEP()'>
          <i class="fas fa-caret-left"></i>
          <div class="Component_ButtonCreative">Digitar outro CEP</div>
        </div>

        <div class="Container_Content_Button __is-blue" routerLink="/">
          <i class="fas fa-caret-left"></i>
          <div class="Component_ButtonCreative">Sair</div>
        </div>
      </div>
    </div>
  </div>
  <div class="PlanoPage_Container">
    <section class="PlanoPage_Title" *ngIf="planoSelecionado">
      <div class="PlanoPage_Title_Container">
        <div class="Title">
          <!-- <div class="Informacoes_Container_Back Component_Button_Back CustomButtonBack">
            <a routerLink="/">
              <i class="fas fa-chevron-left"></i>
            </a>
          </div> -->

          <h1>{{planoSelecionado.titulo}}</h1>
        </div>
      </div>
    </section>

    <section class="PlanoPage_Plano" *ngIf="planoSelecionado">
      <div class="PlanoPage_Plano_Container">
        <div class="PlanoImage">
          <div class="Image">
            <img [src]="imgPlano"
              [ngClass]="{ '__is-bigger': planoSelecionado.uid === '03C0D96D-2240-46E4-906D-EA66754BFF77' }">
          </div>
          <div class="Title">{{planoSelecionado.titulo}}</div>

          <div class="Detalhamento_Plano_VerMais">
            <button class="Plano_VerMais_Button" (click)="openPlanPopup()">
              Ver mais
            </button>
          </div>
        </div>

        <div class="PlanoFeatures">
          <div class="Features">
            <p class="Feature">
              Plano voltado para serviços de gestão condominial da APSA, que proporcionam todo suporte e recursos
              necessários para garantir a eficiência administrativa e o gerenciamento completo das questões burocráticas,
              financeiras, fiscais e legais, seja ele presencial ou digital.
            </p>
            <p>
              A APSA auxilia o trabalho do síndico com serviços flexíveis e diferenciados, feitos sob medida para atender
              as necessidades de condomínios residenciais . Oferece atendimento presencial, digital ou remoto e diversas
              opções de planos e serviços que se adequam as necessidades do seu condomínio residencial.  
            </p>
              
            <div class="OpenLink" (click)="openPlanPopup()">Clique aqui e confira os benefícios do plano!</div>
          </div>
          <!-- <ul class="Features">
            <li
              *ngFor="let feature of planoSelecionado.servicos"
              class="Feature"
              [innerHTML]="feature.descricao"
            ></li>
          </ul> -->
        </div>

        <!-- <p class="PlanoDescription">
          {{planoSelecionado.descricao}}
        </p> -->

      </div>
    </section>
    <!-- <div class="PlanoPage_Container_BackButton">
      <a routerLink="/">Voltar</a>
    </div> -->

    <section class="PlanoPage_Form">
      <h2 class="PlanoPage_Form_Title">
        <b>Para iniciarmos sua simulação</b>, precisamos que você preencha alguns dados antes:
      </h2>

      <form class="PlanoPage_Form_Container" [formGroup]="form" (keyup.enter)='simular()'>

        <div class="FormGroup" [ngClass]="setClassError('cpf')">
          <label class="FormGroup_Label" for="cpf">CPF</label>

          <div class="InputContainer">
            <input #inputCpf name="cpf" id="cpf" placeholder="000.000.000-00" type="tel" mask='000.000.000-00'
              formControlName="cpf" (change)='validaCpf(inputCpf.value)'>
          </div>
          <div class="FormGroup_Erro">Este campo é obrigatório</div>
        </div>

        <div class="FormGroup" [ngClass]="setClassError('nome')">
          <label class="FormGroup_Label" for="nome">Nome e sobrenome</label>

          <div class="InputContainer __is-disable">
            <input name="nome" id="nome" type="text" formControlName="nome" placeholder="John Doe" readonly>
          </div>
          <div class="FormGroup_Erro">Este campo é obrigatório</div>
        </div>

        <div class="FormGroup" [ngClass]="setClassError('telefone')">
          <label class="FormGroup_Label" for="telefone">Telefone</label>

          <div class="InputContainer __is-telefone">
            <input #inputTel name="telefone" placeholder="(00) 0000 - 0000" id="telefone" type="tel"
              mask='(00) 0000-0000||(00) 0 0000-0000' formControlName="telefone"
              (keyup)='verificaTelefone(inputTel.value)'>
          </div>
          <div class="FormGroup_Erro">{{erroTelefone}}</div>
        </div>

        <div class="FormGroup" [ngClass]="setClassErrorEmail('email')">
          <label class="FormGroup_Label" for="email">
            E-mail
          </label>

          <div class="InputContainer">
            <input name="email" placeholder="johndoe@loremipsum.com" id="email" type="email" formControlName="email">
          </div>

          <div class="FormGroup_Erro">{{erroEmail}}</div>
        </div>

        <div class="FormGroup" [ngClass]="setClassError('nomeDoCondominio')">
          <label class="FormGroup_Label" for="nomeDoCondominio">
            Nome do condomínio
          </label>

          <div class="InputContainer">
            <input name="nomeDoCondominio" placeholder="APSA Garden" id="nomeDoCondominio" type="text"
              formControlName="nomeDoCondominio">
          </div>
          <div class="FormGroup_Erro">Este campo é obrigatório</div>
        </div>

        <div class="FormGroup" [ngClass]="setClassError('cepDoCondominio')">
          <label for="cepDoCondominio" class="FormGroup_Label">
            CEP do condomínio
          </label>

          <div class="InputContainer">
            <input name="cepDoCondominio" placeholder="00000-000" mask='00000-000' id="cepDoCondominio" type="tel"
              formControlName="cepDoCondominio">
          </div>

          <div class="FormGroup_Erro">Este campo é obrigatório</div>
        </div>

        <div class="SubmitButton" (click)='simular()'>
          <div class="Container_Content_Button __is-orange" [ngClass]="{'__is-disable': botaoDesabilitado()}">
            <i class="fas fa-caret-left"></i>

            <div class="Component_ButtonCreative">
              Simular
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</div>

<app-ligthboxes-default id="custom-generico" className="__is-open"></app-ligthboxes-default>

<app-plan-popup [isOpen]="isPlanPopupOpen" (onPlanChange)="handlePlanChange()" (onClose)="closePlanPopup()"
  [showOnlyOnePlan]="planoSelecionado"></app-plan-popup>