import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Validations } from 'src/app/shared/components/utils/validation';
import { PlanoService } from '../../services/plano.service';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { Router } from '@angular/router';
import { ContratacaoService } from 'src/app/modules/contratacao/services/contratacao.service';
import { StateService } from 'src/app/shared/services/stateService';
import { HomeService } from 'src/app/modules/home/services/home.service';
// import { setServers } from 'dns';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public isMenuMobileOpen: boolean = false;
  public form: FormGroup;

  private validacao = Validations

  public planoSelecionado;
  public imgPlano;
  public cepValido = true;

  public ultimoCpfDigitado: string;
  public ultimoCep: string;

  public mensagemErro: string;
  public isSubmitted = false;

  public erroTelefone = 'Este campo é obrigatório';
  public erroEmail = 'Este campo é obrigatório';

  public isPlanPopupOpen: boolean = false;
  public selectedPlanIndex: number = 1;

  public listaPlanos = [];

  constructor(
    public fb: FormBuilder,
    private planoService: PlanoService,
    private lightBoxesService: LightBoxesService,
    private router: Router,
    private contratacaoService: ContratacaoService,
    private stateService: StateService,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.getPlanos();
    this.createForm();
    this.carregaPlanoSelecionado();
    this.recuperaInfoStorage();
  }

  getPlanos() {
    const planos = JSON.parse(sessionStorage.getItem('planosDisponiveis'))
    if (planos == null) {
      this.stateService.createTokenApiCondominioDigital().subscribe(item => {
        if (item && item.acessToken) {
          sessionStorage.setItem('tokenApi', item.acessToken);
          this.homeService.getPlanos().subscribe(item => {
            this.listaPlanos = item
            sessionStorage.setItem('planosDisponiveis', JSON.stringify(item))
            this.contratarCompleto();
          })
        }
      })
    } else { this.listaPlanos = planos }
  }

  contratarCompleto() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', 'assets/img/plano 2.png');
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(this.listaPlanos[1]));

    this.router.navigate(['plano']);
  }

  createForm() {

    const validaEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.form = this.fb.group({
      nome: ['', [Validators.required, Validators.pattern(/\s[a-zA-Z \-\']+/)]],
      cpf: ['', Validators.required],
      telefone: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.pattern(validaEmail)]],
      nomeDoCondominio: ['', Validators.required],
      dataNascimento: ['', Validators.required],
      cepDoCondominio: ['', [Validators.required, Validators.minLength(8)]],
      nomeMae: '',
      genero: ''
    });
  }

  get formControls() {
    return this.form.controls;
  }

  recuperaInfoStorage() {
    const dadosPessoais = JSON.parse(sessionStorage.getItem('dadosPessoais'))
    if(dadosPessoais){
      this.form.patchValue(dadosPessoais)
    }
  }

  carregaPlanoSelecionado() {
    this.planoSelecionado = JSON.parse(sessionStorage.getItem('planoSelecionado'))
    if(this.planoSelecionado == null) {
      const planosDisponiveis = JSON.parse(sessionStorage.getItem('planosDisponiveis'))
      if(planosDisponiveis == null) this.router.navigate(['/'])
      const indice = (planosDisponiveis.length >= 1)? 1:0
      this.planoSelecionado = planosDisponiveis[indice]
      sessionStorage.setItem('planoSelecionado',JSON.stringify(this.planoSelecionado))
    }
    this.imgPlano = sessionStorage.getItem('imgPlano');
    if(this.imgPlano == null) {
      this.imgPlano = 1
      sessionStorage.setItem('imgPlano', 'assets/img/plano 1.png')
    }
  }

  validaCpf(cpf: string) {
    if (cpf.length == 14 && cpf != this.ultimoCpfDigitado) {
      if (this.validacao.ValidarCpf(cpf)) {
        this.planoService.getCpfParceiro(cpf).subscribe(item => {
          
          if(item.body && item.status == 200){
            console.log(item.body)
            this.formControls.dataNascimento.patchValue(item.body.nascimento)
            this.formControls.nome.patchValue(item.body.nome)
            
            this.formControls.genero.patchValue(item.body.genero)
            this.formControls.nomeMae.patchValue(item.body.mae)

            this.ultimoCpfDigitado = cpf;
            return this.form.valid
          } else{
            this.lightBoxesService.open(
              'custom-generico', {
              title: 'Atenção',
              text: 'O CPF é inexistente. Por favor, digite novamente.'
            }, 5000
            )
            this.formControls.cpf.patchValue('')
            return this.form.valid
          }
        }), err => {
          if(err.status == 400) {
            this.validaCpf(cpf)
          } else {
            this.lightBoxesService.open(
              'custom-generico', {
              title: 'Atenção',
              text: 'O CPF é inválido. Por favor, digite novamente.'
            }, 5000
            )
            this.formControls.cpf.patchValue('')

          }
          return this.form.valid
        }

      } else {
        this.lightBoxesService.open(
          'custom-generico', {
          title: 'Atenção',
          text: 'O CPF é inválido. Por favor, digite novamente.'
        }, 5000
        )
        this.formControls.cpf.patchValue('')
        return this.form.valid
      }

    }
  }

  verificaTelefone(telefone: string){
    if(telefone.length >= 10) {
      if(this.numeroTelefoneIgual(telefone)){
        this.formControls.telefone.markAsTouched()
      }
    }
  }

  numeroTelefoneIgual(telefone: string){
    if(
      telefone.includes('0000000000') ||
      telefone.includes('1111111111') ||
      telefone.includes('2222222222') ||
      telefone.includes('3333333333') ||
      telefone.includes('4444444444') ||
      telefone.includes('5555555555') ||
      telefone.includes('6666666666') ||
      telefone.includes('7777777777') ||
      telefone.includes('8888888888') ||
      telefone.includes('9999999999') 
    ) return true
  }

  validaCEP() {
    const cep = this.formControls.cepDoCondominio.value
    if (cep.length == 8) {
      this.contratacaoService.getCEP(cep).subscribe(item => {
        if(item){
          this.cepValido = item.areaAtendida
          sessionStorage.setItem('dadosCep', JSON.stringify(item))
          if(item.areaAtendida) this.router.navigate(['/simulacao'])
        } else {
          this.cepValido = false
        }
      })
      
    } 

  }

  // getMensagemErro() {
  //   this.mensagemErro = '';
  //   if (!this.formControls.email.valid) this.mensagemErro = 'É necessário inserir um email válido.'
  //   if (!this.formControls.telefone.valid) this.mensagemErro = 'É necessário inserir um telefone válido.'
  //   if (!this.formControls.nome.valid) this.mensagemErro = 'É necessário preencher nome e sobrenome.'
  //   if (this.mensagemErro == '') this.mensagemErro = 'É necessário preencher todos os campos.'
  // }

  resetaCEP() {
    this.formControls.cepDoCondominio.patchValue('')
    this.cepValido = true;
  }

  simular() {
    this.isSubmitted = true;
    if (this.form.valid) {
    
    console.log('value', this.form.value)
    console.log('validação', this.form.valid)
      this.planoService.cadastroLead(this.form.value, this.cepValido).subscribe(item => {
        sessionStorage.setItem('idLead', JSON.stringify(item))
        sessionStorage.setItem('dadosPessoais', JSON.stringify(this.form.value))
        //o redirecionamento foi movido para dentro da validação de CEP, a última necessária para prosseguir
        this.validaCEP()
      }, err => {
        this.lightBoxesService.open(
          'custom-generico', {
            title:'Atenção',
            text: 'Ocorreu um erro com sua simulação. Tente novamente.'
          }, 5000
        )
      })

    } else {
      // this.getMensagemErro()
      // this.lightBoxesService.open(
      //   'custom-generico', {
      //   title: 'Atenção',
      //   text: this.mensagemErro
      // }, 5000
      // )
    }
    
  }

  botaoDesabilitado() {
    if (this.form.valid) {
      return false
    } else {
      return true
    }
  }

  openMenuMobile() {
    this.isMenuMobileOpen = true;
  }

  closeMenuMobile() {
    this.isMenuMobileOpen = false;
  }

  setClassError(input: string) {
    const formInput = this.form.get(input)
    if(this.numeroTelefoneIgual(formInput.value)){
      this.erroTelefone = 'Número inválido.'
      return '__is-error'
    } else {
      this.erroTelefone = 'Este campo é obrigatório';
      return {
        '__is-error': this.isSubmitted && formInput.invalid
      };
    }

    }

    setClassErrorEmail(input: string) {
      const formInput = this.form.get(input)
      if(formInput.value && formInput.invalid){
        this.erroEmail = 'Insira um email válido.';
        return { 
          '__is-error': this.isSubmitted && formInput.invalid
        };
      } else {
        this.erroEmail = 'Este campo é obrigatório';
        return { 
          '__is-error': this.isSubmitted && formInput.invalid
        };
      }
    }

    //POPUP SAIBA MAIS PLANO

    openPlanPopup() {
      this.isPlanPopupOpen = true;
      this.disablePageScroll();
    }

    closePlanPopup() {
      this.isPlanPopupOpen = false;
      this.enablePageScroll();
    }

    disablePageScroll() {
      const body = document.querySelector('body');
  
      body.style.overflow = 'hidden';
    }
  
    enablePageScroll() {
      const body = document.querySelector('body');
  
      body.style.overflow = 'auto';
    }

    handlePlanChange() {
      console.log("Trocando plano..");
    }
}
