import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnInit } from '@angular/core';
import { GetPlanosDTO } from 'src/app/modules/home/services/DTO/GetPlanos.DTO';
import { PlansService } from '../../services/plans-service.service';

@Component({
  selector: 'app-plan-popup',
  templateUrl: './plan-popup.component.html',
  styleUrls: ['./plan-popup.component.scss']
})
export class PlanPopupComponent implements OnInit {

  public slickConfig = {
    mobileFirst: true,
    dots: true,
    infinite: true,
    arrows: true,
    prevArrow: '<div class="slick-arrow slick-prev"><i class="fas fa-caret-left"></i></div>',
    nextArrow: '<div class="slick-arrow slick-next"><i class="fas fa-caret-right"></i></div>',
    adaptiveHeight: true,
    slidesToShow: 1,
  };
  public plans: GetPlanosDTO[];
  public imgName: string;

  @Input() isOpen: boolean;
  @Input() slideToFocus: number;
  @Input() showOnlyOnePlan: GetPlanosDTO = null;
  @Output() onClose = new EventEmitter();
  @Output() onSimulate = new EventEmitter<GetPlanosDTO>();
  @Output() onPlanChange = new EventEmitter<GetPlanosDTO>();
  @ViewChild('carousel', { static: false }) carousel: any;

  constructor(
    public plansService: PlansService,
  ) { }

  ngOnInit() {
    this.imgName = sessionStorage.getItem('imgPlano');

    if(sessionStorage.planosDisponiveis) {
      const plansInSessionStorage = sessionStorage.getItem('planosDisponiveis');

      this.plans = JSON.parse(plansInSessionStorage);
    }
    else {
      this.plansService.getPlansFromAPI().subscribe(plans => {
        this.plans = plans; 
      });
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.makeSlickfocusSpecificSlide(this.slideToFocus);
    window.setTimeout(() => this.updateCarousel(), 100);
  }

  makeSlickfocusSpecificSlide(slideIndex: number) {
    const carouselElement = this.carousel?.el?.nativeElement;

    if(carouselElement) {
      carouselElement.slick.goTo(slideIndex);
    }
  }

  updateCarousel() {
    const carouselElement = this.carousel?.el?.nativeElement;

    if(carouselElement?.slick) {
      carouselElement.slick.refresh();
    }
  }

  emitClose() {
    this.onClose.emit();
  }

  handleSimulate(plan: GetPlanosDTO) {
    this.emitSimulate(plan);
  }

  emitSimulate(planToEmit: GetPlanosDTO) {
    this.onSimulate.emit(planToEmit);
  }

  emitPlanChange(planToEmit: GetPlanosDTO) {
    this.onPlanChange.emit();
  }
}
