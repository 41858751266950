
<div class="MenuMobile" [ngClass]="{ '__is-active': isMobileMenuOpen() }">
  <div class="MenuMobile_Content">
    <div class="MenuMobile_Content_LineOne">
      <div class="Content_LineOne_Name">
        Menu
      </div>

      <div class="Content_LineOne_Menu" (click)="closeMenuMobile()">
        <i class="fas fa-times"></i>
      </div>
    </div>

    <ul>
      <a routerLink="/" fragment="Sobre" class="Scroll RemoveMobileLink" (click)="closeMenuMobile()">
        <li>Sobre</li>
      </a>

      <a routerLink="/" fragment="Planos" class="Scroll RemoveMobileLink" (click)="closeMenuMobile()">
        <li>Planos</li>
      </a>

      <!-- <a routerLink="/simulacao" (click)="closeMenuMobile()">
        <li>Simule</li>
      </a>

      <a routerLink="/" fragment="Servicos" class="Scroll RemoveMobileLink" (click)="closeMenuMobile()">
        <li>Serviços</li>
      </a> -->

      <a routerLink="/" fragment="Depoimentos" class="Scroll RemoveMobileLink" (click)="closeMenuMobile()">
        <li>Depoimentos</li>
      </a>

      <a routerLink="/faq">
        <li>Ajuda</li>
      </a>

      <a routerLink="/" fragment="Contato" class="Scroll RemoveMobileLink" (click)="closeMenuMobile()">
        <li>Contato</li>
      </a>
    </ul>
  </div>
</div>