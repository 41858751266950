import { Component, OnInit } from '@angular/core';
import { MenuMobileService } from '../../services/menu-mobile.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public menuMobileService: MenuMobileService,
    private router: Router,
    
  ) { }

  ngOnInit(): void {
  }

  redirecionaHome() {
    const rotaAtual = this.router.routerState.snapshot.url
    console.log(rotaAtual)
    if (rotaAtual == '/contratacao/concluida') {
      this.router.navigate(['/'], { queryParams: { contratacao: 'true' } })
    } else {
      this.router.navigate(['/'])
    }
  }

  handleMenuClick() {
    this.menuMobileService.toggle();
  }
}
