<div
  class="CompartivePlanPopupComponent"
  [ngClass]="{ '__is-active': isOpen }"
>
  <div class="Popup">
    <div class="PopupClose">
      <i class="fas fa-times" (click)="emitClose()"></i>
    </div>

    <h1 class="Title">Planos</h1>

    <p class="Description">
      O Condomínio Digital Apsa é a forma de administração mais eficiente
      para o seu condomínio. Seus planos combinam o que há de melhor em
      controle administrativo, financeiro e gerencial, além da possibilidade
      de gestão de funcionários e folha de pagamento. 
    </p>

    <p class="Description">
      Conheça a tradição da consultoria condominial Apsa aliada 
      à experiência completamente digital, inovadora, inclusiva 
      e participativa para você e seus condôminos.
    </p>

    <table>
      <thead>
        <tr>
          <th class="ServiceLabel">
            <div class="SeviceLabelContainer">
              Serviço
            </div>
          </th>

          <th>
            <div class="PlanImageContainer">
              <img src="../../../../assets/img/plano 2 white.png">
            </div>
            
            <div class="PlanTitle">
              PLANO DIGITAL BÁSICO 
            </div>
          </th>

          <th>
            <div class="PlanImageContainer">
              <img src="../../../../assets/img/plano 1 white.png">
            </div>

            <div class="PlanTitle">
              PLANO DIGITAL COMPLETO 
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="Large">
            Atendimento às demandas administrativas
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Cotas condominiais e boletos digitais
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Realização de pagamentos
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Controle orçamentário
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Extratos, relatórios e prestações de contas interativos
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Digitalização de documentos condominiais digitalizados 
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Agendamento e reservas de espaço 
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Mural de avisos
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Livro de ocorrências
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Cotação online de serviços
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Admissão de funcionários
          </td>

          <td class="Small">
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Controle de ponto e horas extras
          </td>

          <td class="Small">
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Controle e simulação de férias
          </td>

          <td class="Small">
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Processamento de folha de pagamento
          </td>

          <td class="Small">
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>

        <tr>
          <td class="Large">
            Simulação de rescisões
          </td>

          <td class="Small">
          </td>

          <td class="Small">
            <i class="fas fa-check"></i>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td class="Large"></td>

          <td class="Small">
            <button (click)="contratarEssencial()">Contrate agora</button>
          </td>

          <td class="Small">
            <button (click)="contratarCompleto()">Contrate agora</button> 
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>