import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TokenResultDTO } from '../DTOs/TokenResultDTO';

@Injectable({
    providedIn: 'root'
  })

  export class StateService {
    private urlApiHost = environment.apiContratacao;
    private acessoExpirado: boolean = false;
  
    constructor(private http: HttpClient) { }

    public getTokenApiCondominioDigital(): string{
        var sessao = sessionStorage.getItem('tokenApi')
        if(sessao){
            return sessao;
        } else {
            this.createTokenApiCondominioDigital().subscribe(item => {
                if(item && item.acessToken){
                    sessionStorage.setItem('tokenApi', item.acessToken);
                    return item.acessToken;
                }
            });
        }
    }

    public createTokenApiCondominioDigital() { 
        const headers = new HttpHeaders({
          'Content-Type': 'application/json'
        });

        const data = {
          login: this.urlApiHost.acesso.login,
          senha: this.urlApiHost.acesso.senha
        }

        return this.http.post<any>(
          `${this.urlApiHost.host +
          this.urlApiHost.endpoints.token}`,
          data,
          {headers: headers}
        );
      }





    
}