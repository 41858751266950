import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StateService } from 'src/app/shared/services/stateService';
import { environment } from 'src/environments/environment';
import { GetPlanosDTO } from './DTO/GetPlanos.DTO';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private url: string;
  private methods;

  constructor(
    private http: HttpClient,
    private stateService: StateService
  ) {
    this.url = environment.apiContratacao.host
    this.methods = environment.apiContratacao.endpoints.home
   }

   private getHeader(){
     return new HttpHeaders({
       'Content-Type' : 'application/json',
       'Authorization' : `Bearer ${this.stateService.getTokenApiCondominioDigital()}`
     })
   }

   cadastraContato(body){
    return this.http.post(`${this.url + this.methods.cadastraContato}`, body,
      {headers:this.getHeader()})
   }

   getPlanos(){
     return this.http.get<Array<GetPlanosDTO>>(`${this.url + this.methods.getPlanos}`, {headers:this.getHeader()})
   }
}
