import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild
} from '@angular/core';

import { ClickSignService } from '../../shared/services/click-sign.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HomeService } from './services/home.service';
import { StateService } from 'src/app/shared/services/stateService';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { GetPlanosDTO } from './services/DTO/GetPlanos.DTO';
import { ContratacaoService } from '../contratacao/services/contratacao.service';
import { ISlickRef, updateSlick } from 'src/app/shared/utils/slick';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  public Container_Content_Cards = {
    mobileFirst: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 12000,
    arrows: true,
    pauseOnFocus: true,
    prevArrow: '<div class="slick-arrow slick-prev"><i class="fas fa-caret-left"></i></div>',
    nextArrow: '<div class="slick-arrow slick-next"><i class="fas fa-caret-right"></i></div>',
    pauseOnDotsHover: true,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    }]
  };

  public Planos_ContainerNovo_TabelaMobile = {
    mobileFirst: true,
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 12000,
    arrows: true,
    pauseOnFocus: true,
    prevArrow: '<div style="position: absolute; top: 0; left: -10px; font-size: 32px; color: #003770; z-index: 9999;"><i class="fas fa-caret-left"></i></a>',
    nextArrow: '<div style="position: absolute; top: 0; right: -10px; font-size: 32px; color: #003770; z-index: 9999;"><i class="fas fa-caret-right"></i></div>',
    pauseOnDotsHover: true,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    }]
  };

  public SlickDepoimentos = {
    mobileFirst: true,
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 12000,
    arrows: true,
    pauseOnFocus: true,
    prevArrow: '<div class="slick-arrow slick-prev"><i class="fas fa-caret-left"></i></div>',
    nextArrow: '<div class="slick-arrow slick-next"><i class="fas fa-caret-right"></i></div>',
    pauseOnDotsHover: true,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
    ]
  };

  public ApsaNaMidia_Container_Content = {
    mobileFirst: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 12000,
    arrows: true,
    pauseOnFocus: true,
    prevArrow: '<div class="slick-arrow slick-prev"><i class="fas fa-caret-left"></i></div>',
    nextArrow: '<div class="slick-arrow slick-next"><i class="fas fa-caret-right"></i></div>',
    pauseOnDotsHover: true,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
    ]
  };

  public cardSlickConfig = {
    mobileFirst: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 12000,
    arrows: true,
    pauseOnFocus: true,
    prevArrow: '<div class="slick-arrow slick-prev"><i class="fas fa-caret-left"></i></div>',
    nextArrow: '<div class="slick-arrow slick-next"><i class="fas fa-caret-right"></i></div>',
    pauseOnDotsHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  };

  public isPopupOpen: boolean = false;
  public isPlanPopupOpen: boolean = false;
  public clickSignWidget: any;
  public isMenuMobileOpen: boolean = false;
  public isContactFormMobileOpen: boolean = false;
  public selectedPlanIndex: number;
  public isComparativeModalOpen: boolean = false;
  public isMobileComparativeModalOpen: boolean = false;
  @ViewChild('carousel', { static: false }) carousel: ISlickRef;

  public form: FormGroup;

  public listaPlanos = [];
  public mensagemErro = '';

  public veioDaContratacao;

  constructor(
    public clickSignService: ClickSignService,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private homeService: HomeService,
    private stateService: StateService,
    private lightboxService: LightBoxesService,
    public router: Router,
    private contratacaoService: ContratacaoService
  ) {
    this.veioDaContratacao = this.route.snapshot.queryParamMap.get('contratacao')
    // this.route.params.subscribe(val => this.ngOnInit())
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(f => {
      const element = document.getElementById(f)
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    })

    if(sessionStorage.getItem('planoSelecionado')) {
      this.router.navigate(['plano']);
    }
    
    this.createForm();
    this.getPlanos();
  }

  ngOnDestroy() {
    this.enablePageScroll();
  }

  ngAfterViewInit() {
    if (this.veioDaContratacao) this.lightBoxAssinado();
    
    window.setTimeout(() => updateSlick(this.carousel), 100);
  }

  lightBoxAssinado() {
    if (this.veioDaContratacao) {
      const chaves = JSON.parse(sessionStorage.getItem('chavesDeAssinatura'));
      this.contratacaoService.checaContrato(chaves.chaveDocumento).subscribe(item => {
        if (item) {
          this.lightboxService.open(
            'custom-generico', {
            title: 'Contrato Assinado',
            text: 'Seu contrato foi assinado com sucesso! Toda comunicação posterior será realizada por email ou SMS, fique atento!'
          }, 10000
          )
        } else {
          this.lightboxService.open(
            'custom-generico', {
            title: 'Atenção!',
            text: "Parece que você ainda não assinou o seu contrato digital. Não se preocupe! Enviamos o contrato com o link de assinatura para seu email. Ficou com alguma dúvida? Acesse nossa central de ajuda ou fale com um de nossos consultores!"
          }, 10000
          )
        }
      })
    }
    sessionStorage.clear();
    this.getPlanos();
  }

  //FORM DE CONTATO
  createForm() {
    this.form = this.fb.group({
      nome: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefone: ['', [Validators.required, Validators.minLength(10)]],
      mensagem: ['', Validators.required]
    })
  }

  get formControls() {
    return this.form.controls
  }

  setMensagemErro() {
    this.mensagemErro = '';
    if (!this.formControls.telefone.valid && this.formControls.telefone.value) this.mensagemErro = 'É necessário inserir um telefone válido.';
    if (!this.formControls.email.valid && this.formControls.email.value) this.mensagemErro = 'É necessário inserir um email válido.';
    if (this.mensagemErro == '') this.mensagemErro = 'É necessário o preenchimento de todos os campos.';
  }


  enviarContato() {
    if (this.form.valid) {
      this.homeService.cadastraContato(this.form.value).subscribe(item => {
        this.form.reset()
        this.lightboxService.open(
          'custom-generico', {
          title: 'Sucesso!',
          text: 'Sua mensagem foi enviada! Em breve nossos consultores entrarão em contato!'
        }, 5000
        )
      }, err => {
        this.lightboxService.open(
          'custom-generico', {
          title: '',
          text: 'Ocorreu um erro com seu contato. Tente novamente.'
        }, 5000
        )
      })

    } else {
      this.setMensagemErro();
      this.lightboxService.open(
        'custom-generico', {
        title: '',
        text: this.mensagemErro
      }, 5000
      )
    }
  }

  //

  desabilitaBotao() {
    if (this.form.valid) {
      return false
    } else {
      return true
    }
  }

  //MENU MOBILE
  openMenuMobile() {
    this.isMenuMobileOpen = true;
  }

  closeMenuMobile() {
    this.isMenuMobileOpen = false;
  }

  //

  // LISTA DE PLANOS
  getPlanos() {
    const planos = JSON.parse(sessionStorage.getItem('planosDisponiveis'))
    if (planos == null) {
      this.stateService.createTokenApiCondominioDigital().subscribe(item => {
        if (item && item.acessToken) {
          sessionStorage.setItem('tokenApi', item.acessToken);
          this.homeService.getPlanos().subscribe(item => {
            this.listaPlanos = item
            sessionStorage.setItem('planosDisponiveis', JSON.stringify(item))
            this.contratarCompleto();
          })
        }
      })
    } else { this.listaPlanos = planos }
  }

  handleSaibaMaisSobrePlano(plano: GetPlanosDTO, index: number) {
    const numImg = index + 1;
    this.selectedPlanIndex = index;
    this.openPlanPopup();

    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', numImg.toString());
  }

  simuleSaibaComo() {
    const dadosPessoais = JSON.parse(sessionStorage.getItem('dadosPessoais'));
    console.log('dados', dadosPessoais);
    if (dadosPessoais == null) {
      this.router.navigate(['/plano'])
    } else {
      this.router.navigate(['/simulacao'])
    }

  }

  handlePlanoSelecionadoNoPopup(plano: GetPlanosDTO) {
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(plano));

    this.router.navigate(['plano']);
  }
  //

  handleFaleConoscoClick() {
    this.isContactFormMobileOpen = !this.isContactFormMobileOpen;
  }

  // TODO
  handleScrollClick(event: MouseEvent) {
    console.log('clicked..');
  }

  runClickSignEmbedded() {
    this.isPopupOpen = true;
    window.setTimeout(() => { this.clickSignService.run('container') }, 500);
  }

  scrollSuave(elementId: string) {
    const element = document.getElementById(elementId);
    const box = element.getBoundingClientRect();

    window.scrollTo({
      top: box.y + window.scrollY,
      behavior: 'smooth'
    });
  }

  openPlanPopup() {
    this.isPlanPopupOpen = true;
    this.disablePageScroll();
  }

  openComparativeModal(planIndex: number) {
    this.selectedPlanIndex = planIndex;

    if(this.hasMobileResolution) {
      this.openMobileComparativeModal();
    }
    else {
      this.openDesktopComparativeModal();
    }
  }

  openDesktopComparativeModal() {
    this.isComparativeModalOpen = true;
    this.disablePageScroll();
  }

  openMobileComparativeModal() {
    this.isMobileComparativeModalOpen = true;
    this.disablePageScroll();
  }

  closePlanPopup() {
    this.isPlanPopupOpen = false;
    this.enablePageScroll();
  }

  closeComparativeModal() {
    this.isComparativeModalOpen = false;
    this.enablePageScroll();
  }

  closeMobileComparativeModal() {
    this.isMobileComparativeModalOpen = false;
    this.enablePageScroll();
  }

  disablePageScroll() {
    const body = document.querySelector('body');

    body.style.overflow = 'hidden';
  }

  enablePageScroll() {
    const body = document.querySelector('body');

    body.style.overflow = 'auto';
  }

  get hasMobileResolution() {
    return window.innerWidth <= 768;
  }

  contratarEssencial() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', 'assets/img/plano 1.png');
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(this.listaPlanos[0]));

    this.router.navigate(['plano']);
  }

  contratarCompleto() {
    sessionStorage.removeItem('imgPlano');
    sessionStorage.setItem('imgPlano', 'assets/img/plano 2.png');
    sessionStorage.removeItem('planoSelecionado');
    sessionStorage.setItem('planoSelecionado', JSON.stringify(this.listaPlanos[1]));

    this.router.navigate(['plano']);
  }
}
